import { METRIC_ENUMS } from '@/constants/campaign'

export const COUNTRY_CURRENCY: { [key: string]: any } = {
  vn: 'VND',
  th: 'THB',
  id: 'IDR',
  sg: 'SGD',
  my: 'MYR',
  ph: 'PHP',
}
export const HISTORICAL_METRIC: Record<string, string> = {
  [METRIC_ENUMS.SPEND]: '(sum)',
  [METRIC_ENUMS.UNITS_SOLD]: '(sum)',
  [METRIC_ENUMS.ROAS]: '(avg.)',
  [METRIC_ENUMS.PAID_GMV]: '(sum)',
  [METRIC_ENUMS.ORDERS]: '(sum)',
  [METRIC_ENUMS.CPC]: '(avg.)',
}
export const STRATEGY_SAVE_DATA: Record<string, any> = {
  BASIC: [
    'strategyId',
    'name',
    'store',
    'storeName',
    'platform',
    'channel',
    'channelName',
    'countryCode',
    'version',
    'status',
    'clonedByStrategy',
    'applyTemplateId',
    'deletedRuleIds',
    'activate',
  ],
  BASIC_TARGET: ['applyTo', 'type', 'values', 'deletedTargets'],
  RULES: ['id', 'name', 'sortNo', 'deletedConditionIds', 'deletedActionIds'],
  RULE_CONDITIONS: [
    'id',
    'metric',
    'metricDisplay',
    'metricPeriod',
    'metricIncludeToday',
    'type',
    'sortNo',
    'definitionId',
    'operatorValue',
    'inputValue',
    'componentType',
    'dropDefinitionId',
    'dropDefinitionName',
    'includeToday',
    'currency',
    'isDisabled',
  ],
  RULE_ACTIONS: [
    'id',
    'definitionId',
    'sortNo',
    'type',
    'metric',
    'operatorValue',
    'inputValue',
    'currency',
    'isDisabled',
  ],
  RULE_REALLOCATE: ['deletedConditionReallocationIds'],
  RULE_PERFORMER: [
    'metric',
    'ruleConditionDefinitionId',
    'orderBy',
    'values',
    'deletedPerformerIds',
  ],
  SCHEDULE: [
    'type',
    'startDate',
    'endDate',
    'crons',
    'cronDisplay',
    'noEndDate',
    'excludeDates',
    'deletedCronIds',
    'deletedExcludeDateIds',
  ],
}
