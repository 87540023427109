import type { Dayjs, ManipulateType } from 'dayjs'
import $day from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import weekday from 'dayjs/plugin/weekday'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'
import { filter, fromPairs, inRange, map, sortBy } from 'lodash-es'
import { isBlank } from './util'
import {
  DateFormat,
  FREQUENCY_TYPE,
  TIMEZONE_COUNTRY,
  WEEK_FORMAT,
} from '@/constants/common'
import { shortcuts } from '@/configs/date'

$day.extend(timezone)
$day.extend(isSameOrAfter)
$day.extend(relativeTime)

const defaultFmt = 'YYYY-MM-DD'
type DAY_OF_WEEK = 0 | 1 | 2 | 3 | 4 | 5 | 6

/**
 * 日期格式化
 * @param time 日期参数
 * @param format? [default=YYYY-MM-DD] 格式化字符串
 */
export function formatDate(
  time: string | Dayjs | number | Date,
  format?: string,
) {
  const dayTime = $day(time)
  return dayTime.isValid()
    ? dayTime.format(format || defaultFmt)
    : (time as string)
}

/**
 * 日期格式化成timestamp
 * @param time 日期参数
 */
export function format2Timestamp(time: string | $day.Dayjs) {
  return formatToTimestamp(time, 0) as number
}

export function formatToTimestamp(
  time: string | $day.Dayjs,
  defaultValue?: number,
) {
  const dayTime = $day(time)
  return dayTime.isValid() ? dayTime.valueOf() : defaultValue
}

/**
 * 日期格式化成东八区时间
 * @param time 日期参数
 */
export function format2T8(
  time: string | $day.Dayjs | number,
  format = defaultFmt,
) {
  const dayTime = $day(time)
  return dayTime.isValid()
    ? dayTime
        .utc()
        .utcOffset(8 * 60)
        .format(format)
    : (time as string)
}

/**
 * 日期零时区格式化
 * @param time 日期参数
 */
export function formatUTCZeroDate(time: string) {
  const dayTime = $day(time)
  return dayTime.isValid() ? `${dayTime.format('YYYY-MM-DD')}T00:00:00Z` : time
}

/**
 * 转换日期参数为本地时区时间
 * @param time 日期参数
 * @param format? 格式化字符串
 */
export function convertUTCToLocal(time: string | Date, format?: string) {
  if (!time || !$day(time).isValid()) return time
  const utcTime = $day.utc(time).toDate()
  return $day(utcTime).format(format || defaultFmt)
}

/**
 * 计算两日期的天数差
 * @param start 被减日期参数
 * @param end 日期参数
 */
export function daysDifference(start: string, end: string) {
  const diff = $day(end).diff($day(start), 'd', true)
  return diff < 1 ? 0 : Math.ceil(diff)
}

/**
 * Get list day in week
 */
export function getWeekdays() {
  $day.extend(localeData)
  return $day.weekdays()
}

/**
 * Format date in ndatepicker
 * @param time string | number | Date
 * @param format string
 */
export function formatDatePicker(time: number | string | Date, format: string) {
  const formatString = format.replace('yyyy', 'YYYY').replace('dd', 'DD')
  return $day(time).format(formatString)
}

/**
 * Get now time local
 * @param timestamp boolean
 * @param tz string
 * @returns Date
 */
export function now(timestamp?: boolean, tz?: string): number | Dayjs {
  $day.extend(timezone)
  const nowTime = $day()
  if (tz) {
    nowTime.tz(tz)
  }
  return timestamp ? nowTime.valueOf() : nowTime
}

/**
 * Check disable pass days
 * @param current number
 * @returns boolean
 */
export function checkDisablePassDate(current: number) {
  return $day().isAfter(current, 'date')
}

/**
 * caculate compare date by mom in day
 * @param current current date
 */
export function calculateMom(
  current: (number | string | Date | Dayjs)[],
): Dayjs[] {
  if (!current.length) return []
  const [date1, date2] = current
  const diff = $day(date2).diff($day(date1), 'day')

  const end = $day(date1).subtract(1, 'day')
  const start = end.subtract(diff, 'day')
  return [start, end]
}

export function parseDateFormat(
  time: number | string | Date | Dayjs,
  format: string,
) {
  return $day($day(time).format(format))
}

export function checkDisableRangeDate(
  current: number,
  start?: number,
  end?: number,
) {
  const startTime = start ?? $day()
  if (!isBlank(end) && end !== 0) {
    return (
      $day(startTime).isAfter(current, 'date') ||
      $day(end).isBefore(current, 'date')
    )
  }
  return $day(startTime).isAfter(current, 'date')
}

export function convertTimestampToDate(data: any[]) {
  return sortBy(
    map(data, time => [formatDate(time.fromTime), formatDate(time.toTime)]),
  )
}

export function today(timestamp?: boolean, tz?: string) {
  const nowTime = now(false, tz)
  const todayTime = $day(formatDate(nowTime))
  return timestamp ? todayTime.valueOf() : todayTime
}

export function yesterday(timestamp?: boolean, tz?: string) {
  const nowTime = now(false, tz)
  const yesterdayTime = $day(formatDate(nowTime)).subtract(1, 'day')
  return timestamp ? yesterdayTime.valueOf() : yesterdayTime
}

export function dayAfter(n: number, timestamp?: boolean, tz?: string) {
  const nowTime = now(false, tz)
  const nDayTime = $day(formatDate(nowTime)).subtract(n, 'day')
  return timestamp ? nDayTime.valueOf() : nDayTime
}

export function checkDate(time: number | string | Date | Dayjs) {
  return $day(time).isValid()
}

export function formatDateWithCountry(
  time: string | $day.Dayjs | number,
  format?: string,
  countryCode?: string,
) {
  const dayjs = $day(time)
  return dayjs.isValid()
    ? dayjs
        .tz(TIMEZONE_COUNTRY[countryCode ?? 'vn'])
        .format(format ?? DateFormat.DEFAULT_DATETIME)
    : (time as string)
}

export function parseDataWithCountry(
  time: string | $day.Dayjs | number,
  countryCode?: string,
) {
  return $day(time).tz(TIMEZONE_COUNTRY[countryCode ?? 'vn'])
}

export function generateDateRange(
  interval: string,
  number?: number,
  format?: string,
  startDate?: number | string | Date,
  endDate?: number | string | Date | Dayjs,
) {
  const dates: Date[] = []
  const increment: Record<string, any> = {
    [FREQUENCY_TYPE.DAILY]: { method: 'Date', value: number || 1 },
    [FREQUENCY_TYPE.HOURLY]: { method: 'Hours', value: number || 1 },
    [FREQUENCY_TYPE.MINUTELY]: { method: 'Minutes', value: number || 1 },
    [FREQUENCY_TYPE.WEEKLY]: { method: 'Date', value: number || 7 },
  }

  const incrementNumber = increment[interval]
  if (!incrementNumber) {
    throw new Error('Invalid interval specified.')
  }

  const startTime = startDate || $day().startOf('date').toDate()
  const endTime = endDate || $day().endOf('date').toDate()
  const { method, value }: { method: string; value: number } = incrementNumber
  const setMethod = `set${method}`
  const getMethod = `get${method}`
  const currentDate = new Date(startTime)

  while (currentDate <= endTime) {
    dates.push(new Date(currentDate))
    ;(currentDate[setMethod as keyof Date] as any)(
      (currentDate[getMethod as keyof Date] as any)() + value,
    )
  }

  if (!format) return dates

  return dates.map(date => formatDate(date, format))
}

export function getWeekDayName(dayOfWeek: DAY_OF_WEEK, format?: WEEK_FORMAT) {
  $day.extend(weekday)
  return inRange(dayOfWeek, 0, 7)
    ? $day()
        .weekday(dayOfWeek)
        .format(format || WEEK_FORMAT.DEFAULT)
    : ''
}

export function checkDisablePassSameDate(current: number) {
  return $day().isSameOrAfter(current, 'date')
}

export function formatRelativeTime(time?: string) {
  return $day().to($day(time))
}

export function formatISODateWithCountry(time: number, countryCode?: string) {
  return parseDataWithCountry(time, countryCode).format()
}
export function formatHourMinuteWithCountry(
  time: string, // HH:mm:ss
  countryCode?: string,
) {
  const [hours, minutes] = time.split(':').map(Number)
  const utcDate = new Date(Date.UTC(2024, 0, 1, hours, minutes)) // before 1975 will return UTC+8
  const formatter = new Intl.DateTimeFormat('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: TIMEZONE_COUNTRY[countryCode ?? 'vn'],
    hour12: false,
  })
  return formatter.format(utcDate)
}

export function checkDisableCustomRangeWithCurrent(
  current: number,
  range: [number, ManipulateType],
) {
  const [val, unit] = range
  return $day().subtract(val, unit).isAfter(current, 'date')
}

export function checkDisableFutureDate(current: number) {
  return $day().isBefore(current, 'date')
}

export function checkDisableCustomRange(
  start: number,
  end: number,
  range: [number, ManipulateType],
) {
  const [val, unit] = range
  return $day(start).diff($day(end), unit) > val
}

export function generateShortcutList(lstKey: string[]) {
  return fromPairs(
    filter(shortcuts.flat(), s => lstKey.includes(s.key))?.map(s => [
      s.key,
      s.value.map(v => format2Timestamp(v)) as [number, number],
    ]),
  )
}

export function formatToDateTimeString(timestamp: number): string {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
