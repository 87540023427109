import { defineStore } from 'pinia'
import {
  cloneDeep,
  find,
  flatMap,
  forEach,
  isPlainObject,
  map,
  merge,
  omit,
  pick,
  sortBy,
  toNumber,
  unionBy,
  uniq,
} from 'lodash-es'
import $day from 'dayjs'
import type { StrategyNS } from '@/typings'
import { AssignedStrategy } from '@/typings'
import { COUNTRY_CURRENCY, STRATEGY_SAVE_DATA } from '@/configs/strategy'
import { checkDisablePassDate, format2Timestamp, formatDate } from '@/utils/day'
import { isBlank } from '@/utils/util'
import { DateFormat, FREQUENCY_TYPE } from '@/constants/common'
import {
  REALLOCATE_ENUM,
  SPECIFIC_ROW_KEY,
  STRATEGY_RULE_SCHEDULE,
} from '@/constants/strategy'
import {
  checkApplyTemplate,
  generateDeletedRules,
  generateDeletedSchedule,
  generateOperatorOption,
  generateReallocateForSaas,
  generateScheduleTemplate,
  getCronsDisplayByType,
  getReviewConditionRule,
  mapConditionsRule,
  setConditionValues,
} from '@/utils/strategyUtil'

export const useAssignedStrategyStore = defineStore(AssignedStrategy.name, {
  state: (): AssignedStrategy.State => ({
    data: {
      rows: [],
      total: 0,
    },
    stores: [],
    rules: {
      conditions: [],
      actions: [],
    },
    strategyData: {
      name: '',
      store: '',
      storeName: '',
      platform: '',
      channel: '',
      channelName: '',
      countryCode: '',
      currency: '',
      target: {},
      rules: [],
      schedule: {},
    },
    saveData: {
      name: '',
      store: '',
      storeName: '',
      platform: '',
      channel: '',
      channelName: '',
      countryCode: '',
      currency: '',
      target: {},
      rules: [],
      schedule: {},
    },
    details: {
      countryCode: '',
      name: '',
      platform: '',
      rules: [],
      schedule: {},
      status: '',
      store: '',
      target: {},
      isExpired: false,
    },
    strategyExecutedAction: {
      countryCode: '',
      platform: '',
      rows: [],
    },
    options: {
      type: '',
      rows: [],
      total: 0,
      page: 1,
      pageSize: 20,
    },
    specifics: {
      rows: [],
      total: 0,
      lastRefreshedAt: '',
      selectedMetrics: [],
    },
    validate: [],
    keywords: [],
    execActionStrategy: {
      hasDisplayError: false,
      isReload: false,
    },
    templateList: {},
    executionLog: {},
    discoveryLocation: [],
  }),
  actions: {
    setAssignedStrategyList(data: any) {
      this.data = data
    },
    setStoreAssignedList(data: any) {
      this.stores = data
    },
    setRuleAssignedList(data: any) {
      this.rules = data
    },
    setScheduleData(data: any) {
      this.strategyData.schedule = data
    },
    setSaveBasicData(data: any) {
      this.saveData = pick(data, STRATEGY_SAVE_DATA.BASIC)
      this.saveData.target = pick(data.target, STRATEGY_SAVE_DATA.BASIC_TARGET)
    },
    setSaveRuleData(data: any[], isSaasUser = false) {
      this.saveData.rules = []
      data.forEach(item => {
        this.saveData.rules.push({
          ...pick(item, STRATEGY_SAVE_DATA.RULES),
          conditions: map(item.conditions, cond =>
            pick(cond, STRATEGY_SAVE_DATA.RULE_CONDITIONS),
          ),
          actions: map(item.actions, act => {
            let reallocateInfo: any
            if (!isSaasUser) {
              reallocateInfo = {
                ...pick(act.reallocate, STRATEGY_SAVE_DATA.RULE_REALLOCATE),
                performer: {
                  ...pick(
                    act.reallocate?.performer,
                    STRATEGY_SAVE_DATA.RULE_PERFORMER,
                  ),
                },
                conditions: map(act.reallocate?.conditions, subCond =>
                  pick(subCond, STRATEGY_SAVE_DATA.RULE_CONDITIONS),
                ),
              }
            }
            return {
              ...pick(act, STRATEGY_SAVE_DATA.RULE_ACTIONS),
              reallocate: reallocateInfo,
            }
          }),
        })
      })
    },
    setSaveScheduleData(data: any) {
      this.saveData.schedule = pick(data, STRATEGY_SAVE_DATA.SCHEDULE)
      this.saveData.schedule.cronDisplay = flatMap(data.cronDisplay, item => {
        if (isPlainObject(item)) {
          return item.values.map((val: any) => `${item.dayOfWeek}_${val}`)
        }
        return item
      })
    },
    setStepBasicData(data: StrategyNS.FormBasicProps) {
      this.strategyData.strategyId = data.strategyId
      this.strategyData.name = data.name
      this.strategyData.store = data.store
      this.strategyData.storeName = data.storeName
      this.strategyData.platform = data.platform
      this.strategyData.channel = data.channel
      this.strategyData.channelName = data.channelName
      this.strategyData.countryCode = data.countryCode
      this.strategyData.oldCountryCode = data.oldCountryCode ?? data.countryCode
      this.strategyData.currency = COUNTRY_CURRENCY[data.countryCode]
      this.strategyData.version = data.version
      this.strategyData.status = data.status
      this.strategyData.isExpired = data.isExpired
      this.strategyData.clonedByStrategy = data.clonedByStrategy
      this.strategyData.target = {
        applyTo: data.shopType,
        type: data.type,
        values: data.values,
        displayValues: data.displayValues,
        deletedTargets: uniq(data.deletedTargets),
      }
      this.strategyData.oldTarget = {
        applyTo: data.oldShopType ?? data.shopType,
        type: data.oldType ?? data.type,
        values: data.oldValues ?? data.values,
      }

      this.setSaveBasicData(this.strategyData)
    },
    setStepRuleData(data: StrategyNS.RuleProps[], isSaasUser = false) {
      this.strategyData.rules = []
      data.forEach((item, idx) => {
        this.strategyData.rules.push({
          id: Number(item.id),
          name: item.name,
          sortNo: idx + 1,
          conditions: setConditionValues(
            item.conditions,
            this.strategyData.currency,
          ),
          actions: map(item.actions, (subItem, subIdx) => {
            let reallocateInfo = subItem.reallocate
            if (reallocateInfo && !isSaasUser) {
              const mainConditions: any[] = cloneDeep(item.conditions)
              let definedCondId =
                subItem.reallocate?.deletedConditionReallocationIds ?? []
              const { definedListId, performerInfo, reallocateConditions } =
                generateReallocateForSaas(
                  this.strategyData.currency,
                  mainConditions,
                  subItem.reallocate?.conditions ?? [],
                  this.rules.conditions,
                  subItem?.isDisabled || isSaasUser,
                )
              definedCondId = merge(definedCondId, definedListId)
              reallocateInfo = {
                actionId: subItem.reallocate?.actionId,
                conditions: reallocateConditions,
                performer: merge(subItem.reallocate?.performer, performerInfo),
                deletedConditionReallocationIds: uniq(definedCondId),
              }
            }

            return {
              id: Number(subItem.id),
              definitionId: Number(subItem.definitionId),
              sortNo: subIdx + 1,
              type: subItem.itemOperator?.type ?? subItem.type ?? '',
              metric: subItem.metric,
              metricDisplay: subItem.metricDisplay,
              operatorValue:
                toNumber(subItem.operatorValue) === 0
                  ? ''
                  : subItem.operatorValue,
              operatorName: subItem.operatorName,
              inputValue: Number(subItem.inputValue),
              postFix: subItem.itemOperator?.postFix ?? subItem.postFix ?? '',
              currency: this.strategyData.currency,
              isDisabled: Boolean(subItem?.isDisabled),
              reallocate: reallocateInfo,
            }
          }),
          deletedConditionIds: uniq(item.deletedConditionIds),
          deletedActionIds: uniq(item.deletedActionIds),
        } as StrategyNS.StrategyRuleProps)
      })
      this.setSaveRuleData(this.strategyData.rules, isSaasUser)
    },
    setStepScheduleData(data: StrategyNS.ScheduleProps) {
      this.strategyData.schedule = {
        type: data.frequency,
        startDate: data.startDate === 0 ? '' : $day(data.startDate).format(),
        endDate: data.endDate === 0 ? '' : $day(data.endDate).format(),
        crons: data.cronExpression,
        cronDisplay: data.cronDisplay,
        noEndDate: data.noEndDate,
        excludeDates: sortBy(
          map(data.excludes, time => ({
            ...time,
            fromTime: formatDate(time.fromTime),
            toTime: formatDate(time.toTime),
          })),
          ['fromTime', 'toTime'],
        ),
        firstOccurrence: data.firstOccurrence,
        deletedCronIds: uniq(data.deletedCronIds),
        deletedExcludeDateIds: uniq(data.deletedExcludeDateIds),
      }
      if (data.endDate) {
        this.strategyData.isExpired = checkDisablePassDate(Number(data.endDate))
      } else if (data.endDate === 0) {
        this.strategyData.isExpired = false
      }

      this.setSaveScheduleData(this.strategyData.schedule)
    },
    resetValueFromBasicStep() {
      this.rules = []
      this.clearData()
    },
    clearData() {
      this.strategyData = {
        strategyId: 0,
        name: '',
        store: '',
        storeName: '',
        platform: '',
        channel: '',
        channelName: '',
        countryCode: '',
        target: {},
        rules: [],
        schedule: {},
        version: 0,
        applyTemplateId: 0,
      }
      this.setSaveBasicData(this.strategyData)
      this.saveData.rules = []
      this.saveData.schedule = {}
    },
    resetRuleData() {
      this.strategyData.rules = []
      this.saveData.rules = []
    },
    setStrategyDetailData(data: any) {
      this.details = data
    },
    setStrategyExecutedAction(data: any) {
      this.strategyExecutedAction = data
    },
    setOptions(layer: string, data: any, isReset = false) {
      const listOptions = map(data.rows, r =>
        pick(r, ['id', 'name', 'hash_id', 'hash_ids']),
      )
      if (isReset) {
        this.options[layer] = listOptions
      } else {
        this.options[layer] = unionBy(
          this.options[layer],
          listOptions,
          'hash_id',
        )
      }
    },
    setSpecifics(data: any) {
      this.specifics.rows = data.rows
      this.specifics.total = data.total
      this.specifics.lastRefreshedAt = data.last_refreshed_at
      this.specifics.selectedMetrics = data.selected_metrics
    },
    setValidateData(data: any) {
      this.validate = data?.rules
    },
    setKeywordsData(data: any, isAppend = false) {
      const listInfo = map(data.rows, item => ({
        value: item.keyword,
        label: item.keyword,
      }))
      if (isAppend) this.keywords = unionBy(this.keywords, listInfo, 'value')
      else this.keywords = listInfo
    },
    setDisplayWarningPage(data: any) {
      this.execActionStrategy.hasDisplayError = data
    },
    resetRuleStoreLocalTime() {
      forEach(this.strategyData.rules, rule => {
        forEach(rule.conditions, cond => {
          if (cond.metric === STRATEGY_RULE_SCHEDULE.STORE_LOCAL_TIME) {
            cond.operatorName = undefined
            cond.operatorValue = undefined
            cond.inputValue = undefined
            cond.componentAttrs = undefined
          }
        })
      })
    },
    setDeletedRules(data: number[]) {
      this.strategyData.deletedRuleIds = uniq(data)
      this.saveData.deletedRuleIds = uniq(data)
    },
    setDataStepBasic(
      detail: StrategyNS.StrategyDetailProps,
      isFastCreate = false,
    ) {
      this.setStepBasicData({
        strategyId: Number(detail.id),
        name: detail?.name,
        store: detail?.store,
        storeName: detail?.storeName,
        channel: detail?.channel,
        channelName: detail?.channelName,
        platform: detail?.platform,
        countryCode: detail?.countryCode,
        oldCountryCode: detail?.oldCountryCode ?? detail?.countryCode,
        version: detail?.version,
        type: detail?.target?.type,
        shopType: detail?.target?.applyTo,
        values: isFastCreate
          ? detail?.target?.values
          : map(detail?.target?.values, SPECIFIC_ROW_KEY),
        oldType: detail?.oldTarget?.type ?? detail?.target?.type,
        oldShopType: detail?.oldTarget?.applyTo ?? detail?.target?.applyTo,
        oldValues: detail?.oldTarget?.values ?? detail?.target?.values,
        displayValues: isFastCreate
          ? detail?.target?.displayValues
          : detail?.target?.values,
        status: detail?.status,
        clonedByStrategy: Number(detail?.clonedByStrategy),
        // isExpired: detail?.schedule?.endDate
        //   ? checkDisablePassDate(Number(detail?.schedule?.endDate))
        //   : false,
        isExpired: detail?.isExpired,
      } as StrategyNS.FormBasicProps)
    },
    setDateStepSchedule(
      detail: StrategyNS.StrategyDetailProps,
      setStepBasic = true,
      editMode = false,
    ) {
      if (setStepBasic) this.setDataStepBasic(detail)
      const { lstOldCronsId, lstOldExcludeDateId } = generateDeletedSchedule(
        this.details.schedule,
        this.strategyData.schedule,
        !setStepBasic,
      )
      this.setStepScheduleData({
        frequency: detail?.schedule?.type,
        startDate: format2Timestamp(detail?.schedule?.startDate),
        endDate: isBlank(detail?.schedule?.endDate)
          ? 0
          : format2Timestamp(detail?.schedule?.endDate),
        noEndDate:
          detail?.schedule?.noEndDate ?? isBlank(detail?.schedule?.endDate),
        firstOccurrence: detail?.schedule?.firstOccurrence,
        excludes: detail?.schedule?.excludeDates,
        cronDisplay: getCronsDisplayByType(
          detail?.schedule?.type,
          detail?.schedule?.crons,
        ),
        cronExpression: map(detail?.schedule?.crons, item =>
          map(item.values, v =>
            editMode ? pick(v, ['id', 'expression']) : v.expression,
          ),
        ).flat(),
        deletedCronIds: lstOldCronsId,
        deletedExcludeDateIds: lstOldExcludeDateId,
      } as StrategyNS.ScheduleProps)
    },
    setDataStepRule(
      detail: StrategyNS.StrategyDetailProps,
      setStepBasic = true,
      editMode = false,
    ) {
      this.setDateStepSchedule(detail, setStepBasic, editMode)
      this.strategyData.applyTemplateId = detail.applyTemplateId
      const listOldRuleId = generateDeletedRules(
        this.details.rules,
        this.strategyData.rules,
        !setStepBasic,
      )
      this.strategyData.deletedRuleIds = listOldRuleId
      const ruleInfo: StrategyNS.RuleProps[] = []
      detail.rules.forEach(rule => {
        ruleInfo.push({
          id: Number(rule.id),
          sortNo: rule.sortNo,
          name: rule.name,
          conditions: mapConditionsRule(rule.conditions),
          actions: map(rule.actions, act => {
            const reallocateInfo = {
              actionId: act.definitionId,
              conditions: mapConditionsRule(act.reallocate?.conditions ?? []),
              performer: act.reallocate?.performer,
            }
            return {
              ...act,
              itemOperator: this.findOperatorActionByAction(
                String(act.definitionId),
                act.metric,
              ),
              operators: this.findRuleByAction(act.metric)?.operators,
              reallocate:
                act.metric === REALLOCATE_ENUM.reallocate
                  ? reallocateInfo
                  : undefined,
            }
          }),
        })
      })
      this.setStepRuleData(ruleInfo)
    },
    setTemplateData(key: string, data: any) {
      this.templateList[key] = data.templates
    },
    clearDataWithoutBasic() {
      this.strategyData.applyTemplateId = 0
      this.strategyData.schedule = {}
      this.strategyData.rules = []
      this.saveData.applyTemplateId = 0
      this.saveData.schedule = {}
      this.saveData.rules = []
    },
    applyTemplateById(key: string, id: number, editMode: boolean) {
      const tempInfo = cloneDeep(find(this.templateList[key], ['id', id]))
      this.clearDataWithoutBasic()
      if (tempInfo && checkApplyTemplate(tempInfo)) {
        tempInfo.applyTemplateId = id
        forEach(tempInfo.schedule.crons, cr => {
          forEach(cr.values, crv => {
            crv.id = 0
          })
        })
        forEach(tempInfo.rules, rule => {
          rule.id = 0
          forEach(rule.conditions, cond => {
            cond.id = 0
          })
          forEach(rule.actions, act => {
            act.id = 0
          })
        })
        this.setDataStepRule(tempInfo, false, editMode)
        return true
      }
      return false
    },
    setExecutionLogData(data: any) {
      this.executionLog = data
    },
    setDiscoveryLocationData(data: any, isAppend = false) {
      const listInfo = map(data.rows, item => ({
        value: item.discoveryLocation,
        label: `strategy-detail.specific-list.fields.${item.discoveryLocation}`,
      }))
      if (isAppend)
        this.discoveryLocation = unionBy(
          this.discoveryLocation,
          listInfo,
          'value',
        )
      else this.discoveryLocation = listInfo
    },
    setDataByApplyTo(layer: string, data: any, isAppend = false) {
      if (layer === 'keyword') this.setKeywordsData(data, isAppend)
      else this.setDiscoveryLocationData(data, isAppend)
    },
  },
  getters: {
    getStrategyList(state) {
      return state.data
    },
    getStores(state) {
      return state.stores
    },
    getRules(state) {
      return state.rules
    },
    getChannelByStore(state) {
      return (key: string) =>
        find(state.stores, (item: any) => item.shopId === key)?.channels
    },
    getStrategyData(state) {
      return state.strategyData
    },
    findRuleByMetric(state) {
      return (key: string) =>
        find(state.rules.conditions, (item: any) => item.metric === key)
    },
    findRuleByAction(state) {
      return (key: string) =>
        find(state.rules.actions, (item: any) => item.metric === key)
    },
    findRuleConditionById(state) {
      return (key: string) =>
        find(state.rules.conditions, (item: any) => item.id === key)
    },
    findRuleOperatorActionById() {
      return (key: string, id: string) => {
        const actionInfo = this.findRuleByAction(key)
        return find(actionInfo?.operators, (item: any) => item.id === id)
      }
    },
    findOperatorConditionById() {
      return (key: string, id: string) => {
        const conditionInfo = this.findRuleByMetric(key)
        return find(conditionInfo?.operators, (item: any) => item.value === id)
      }
    },
    findOperatorActionByAction() {
      return (id: string, name: string) => {
        const operatorInfo = this.findRuleOperatorActionById(name, id)
        return generateOperatorOption(operatorInfo ?? {})
      }
    },
    getStrategyDetailData(state) {
      return state.details
    },
    getStrategyExecutedAction(state) {
      return state.strategyExecutedAction
    },
    getOptions(state) {
      return (layer: string) => state.options[layer]
    },
    getSpecificsData(state) {
      return state.specifics
    },
    getValidateData(state) {
      return state.validate
    },
    getDataKeywords(state) {
      return state.keywords
    },
    getStrategyCreateData(state) {
      return state.saveData
    },
    getStrategyReviewData(state) {
      return (isEditMode: boolean) => {
        const scheduleCrons = []
        if (isEditMode) {
          if (state.strategyData.schedule.type === FREQUENCY_TYPE.WEEKLY) {
            scheduleCrons.push(
              ...map(state.strategyData.schedule.cronDisplay, item => ({
                dayOfWeek: item.dayOfWeek,
                values: item.values,
              })),
            )
          } else {
            scheduleCrons.push({
              dayOfWeek: -1,
              values: state.strategyData.schedule.cronDisplay,
            })
          }
        }
        return {
          name: state.strategyData.name,
          store: state.strategyData.store,
          storeName: state.strategyData.storeName,
          channel: state.strategyData.channel,
          channelName: state.strategyData.channelName,
          platform: state.strategyData.platform,
          target: {
            applyTo: state.strategyData.target.applyTo,
            type: state.strategyData.target.type,
            values: state.strategyData.target.displayValues,
          },
          countryCode: state.strategyData.countryCode,
          rules: map(state.strategyData.rules, rule => ({
            id: rule.id,
            name: rule.name,
            sortNo: rule.sortNo,
            conditions: getReviewConditionRule(rule.conditions ?? []),
            actions: map(rule.actions, act => ({
              ...act,
              reallocate: {
                ...omit(act.reallocate, ['deletedConditionReallocationIds']),
                conditions: getReviewConditionRule(act.conditions ?? []),
              },
            })),
          })),
          schedule: {
            type: state.strategyData.schedule.type,
            startDate: isEditMode
              ? formatDate(
                  state.strategyData.schedule.startDate,
                  DateFormat.DATE_HOUR_MINUTE,
                )
              : undefined,
            endDate: isEditMode
              ? formatDate(
                  state.strategyData.schedule.endDate,
                  DateFormat.DATE_HOUR_MINUTE,
                )
              : undefined,
            crons: scheduleCrons,
            excludeDates: state.strategyData.schedule.excludeDates,
            firstOccurrence: state.strategyData.schedule.firstOccurrence,
          },
          status: state.strategyData.status,
          isExpired: state.strategyData.isExpired,
          version: state.strategyData.version,
        }
      }
    },
    getTemplateDataList(state) {
      return (key: string) =>
        state.templateList[key].map((temp: StrategyNS.TemplateDataList) => {
          const keyLang = temp.rules.length === 1 ? 'one-rule' : 'many-rule'
          return {
            id: temp.id,
            title: temp.name,
            content: temp.description,
            rule: {
              key: `strategy-detail.template-info.${keyLang}`,
              params: { num: temp.rules.length },
            },
            schedule: generateScheduleTemplate(temp.schedule),
          }
        })
    },
    getDataByApplyTo(state) {
      return (layer: string) =>
        layer === 'keyword' ? state.keywords : state.discoveryLocation
    },
    getStoreInfoById(state) {
      return (id: string) => find(state.stores, ['shopId', id])
    },
  },
})
